import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Basic} from '../components/SEO';
import {metadata} from '../../config';

const pages = props => {
  const seo = {
    title: `${props.data.page.title} | ${metadata.defaultTitle}`,
    description: props.data.page.title
  }

  return <Layout>
    <Basic seo={seo}/>
    <main className="wrapper page" style={{maxWidth: "1000px"}} data-datocms-noindex>
      <h1 className={'page-title'}>{props.data.page.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: props.data.page.content
        }}
      />
    </main>
  </Layout>
}

export const query = graphql`
  query PageQuery($slug: String) {
      page: datoCmsPage(
          slug: {eq: $slug}
          website: {elemMatch: {name: {eq: "ht"}}}
      ) {
          slug
          title
          content
      }
  }
`
export default pages
